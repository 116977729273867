// import './bootstrap.js';
import intlTelInput from 'intl-tel-input';
import 'intl-tel-input/build/css/intlTelInput.css';
/*
 * Welcome to your app's main JavaScript file!
 *
 * This file will be included onto the page via the importmap() Twig function,
 * which should already be in your base.html.twig.
 */
// import './styles/app.css';

console.log('This log comes from assets/app.js - welcome to AssetMapper! 🎉');

// assets/app.js



document.addEventListener('DOMContentLoaded', function () {
    const input = document.querySelector('.phone-input');
    
    if (input) {
        intlTelInput(input, {
            initialCountry: 'auto',
            geoIpLookup: function (success, failure) {
                fetch('https://ipinfo.io/json', {cache: 'reload'}).then(function(response) {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Failed: ' + response.status);
                }).then(function(ipjson) {
                    success(ipjson.country);
                }).catch(function() {
                    success('US');
                });
            },
            utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.12/js/utils.js'
        });
    }
});
